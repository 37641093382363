/* General Styles */
body {
  margin: 0;
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.bg-custom {
  background: linear-gradient(135deg, #f5f5f5, #e0e0e0);
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  margin: auto;
  margin-top: min(50px, calc(5vw + 20px));
  margin-bottom: min(50px, calc(5vw + 20px));

}

.spinner-border {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}

.cardCTC {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.cardCTC-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.95rem;
}

.cardCTC-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.cardCTC-body {
  padding: 20px;
}

.cardCTC-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.cardCTC-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #007bff;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.h5 {
  font-size: 1.25rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.cardCTC-text {
  line-height: 1.5;
}

strong {
  font-weight: bold !important;
}
