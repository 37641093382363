.has-error .select2-selection {
  border-color: red !important;
}

.has-error .form-control {
  border-color: red;
}

fieldset {
  border: 2px solid #c9c9c97e;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px -5px 10px -5px #1f1f1f3b;
}

legend {
  width: auto;
  color: #ffffff;
  background: #031a61;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 15px;
  letter-spacing: 1px;
}

.form-group.row label {
  text-transform: capitalize;
  font-weight: 500;
}

.btn {
  line-height: normal;
  /* padding:unse; */
  border-radius: 4px !important;
}

.btn-danger {
  background: #ec0037;
}

.modal {
  z-index: 99999999 !important;
}

.admin-dashboard {
  background-color: #673dff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%2368bfcc' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%231389aa' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%2350afcc' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%230e7fa5' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23439eca' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%2310759f' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23468cc5' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23176a98' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%235578ba' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%231e6090' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%236663aa' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%23255688' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
